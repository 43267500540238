<template>
  <div>
    <!-- end modal section -->
    <b-modal
      id="formInfo"
      ok-only
      centered
      size="md"
      content-class="shadow"
      hide-header
      hide-footer
    >
      <div>
        <b-container class="text-center">
          <p style="font-size:18px; margin-top:20px;">
            Jenis Permohonan Perubahan Sudah Sesuai?
          </p>
          <b-row class="pl-3 pt-3">
            <b-col class="d-flex align-items-center">
              <v-icon
                small
                :class="profile ? 'pr-3 ic-gray' : 'pr-3 ic-green'"
              >
                mdi-check-circle
              </v-icon>
              Profil
            </b-col>
          </b-row>
          <b-row class="pl-3 pt-3">
            <b-col class="d-flex align-items-center">
              <v-icon
                small
                :class="structure ? 'pr-3 ic-gray' : 'pr-3 ic-green'"
              >
                mdi-check-circle
              </v-icon>
              Struktur Organisasi
            </b-col>
          </b-row>
          <b-row class="pl-3 pt-3">
            <b-col class="d-flex align-items-center">
              <v-icon
                small
                :class="rekening ? 'pr-3 ic-gray' : 'pr-3 ic-green'"
              >
                mdi-check-circle
              </v-icon>
              Rekening
            </b-col>
          </b-row>
          <b-row class="pl-3 pt-3">
            <b-col class="d-flex align-items-center">
              <v-icon
                small
                :class="cabang ? 'pr-3 ic-gray' : 'pr-3 ic-green'"
              >
                mdi-check-circle
              </v-icon>
              Cabang Ormas
            </b-col>
          </b-row>
        </b-container>
        <div class="text-center">
          <b-button
            variant="outline-secondary"
            class="mt-16 text-center mr-5"
            style="width:185px;"
            @click="$bvModal.hide('formInfo')"
          >
            Tidak
          </b-button>
          <b-button
            variant="primary"
            style="width:185px;"
            class="mt-16 text-center"
            @click="confirmChoice()"
          >
            Ya
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-row class="mb-5">
      <b-col>
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          <b>Jenis Permohonan Perubahan Data</b>
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
          size="sm"
          variant="secondary-outline"
          style="border:1px solid rgba(77, 77, 77, 0.38); color:rgba(77, 77, 77, 0.38); margin-right:16px;"
          @click="hapusSemua()"
        >
          <v-icon
            style="color:rgba(0, 0, 0, 0.38);"
            small
          >mdi-delete-outline</v-icon>
          Hapus Semua
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="pilihSemua()"
        >
          <v-icon
            style="color:white;"
            small
          >mdi-clipboard-check-outline</v-icon>
          Pilih Semua
        </b-button>
      </b-col>
    </b-row>
    <b-card class="shadow">
      <b-container>
        <b-row>
          <b-col sm="12" class="text-center">
            <p style="color:#333333;font-size:16px;font-weight:600;">Silahkan Pilih Jenis Perubahan</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" offset="3" style="padding-top:5rem;">
            <b-row>
              <b-col cols="6">
                <b-card class="shadow border-0 position-relative" style="padding-top:7rem;cursor:pointer;" @click="profile = !profile">
                  <div style="position:relative; width:100%;">
                  <img
                    src="../../../assets/image/images/request/profil.svg"
                    alt="abstract"
                    style="position:absolute;top:-40px;top: -160px;margin: 0 auto;left: 0;right: 0;"
                  />
                  </div>
                  <b-row>
                    <b-col class="text-center">
                      <span style="font-size:14px;font-weight:600;">Profil</span>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-center pt-5">
                        <div class="circle-add" v-if="profile">
                        <v-icon
                          style="color:#339933;font-size:30px;"
                          small
                        >
                          mdi-plus-circle
                        </v-icon>
                        </div>
                        <div class="circle-close" v-else>
                        <v-icon
                          style="color:#FF4433;font-size:30px;"
                          small
                        >
                          mdi-close-circle
                        </v-icon>
                        </div>
                    </b-col>
                  </b-row>
                  
                </b-card>
              </b-col>
              <b-col cols="6">
                <b-card class="shadow border-0 position-relative" style="padding-top:7rem;cursor:pointer;" @click="structure = !structure">
                  <div style="position:relative; width:100%;">
                  <img
                    src="../../../assets/image/images/request/briefcase.svg"
                    alt="abstract"
                    style="position:absolute;top:-40px;top: -160px;margin: 0 auto;left: 0;right: 0;"
                  />
                  </div>
                  <b-row>
                    <b-col class="text-center">
                      <span style="font-size:14px;font-weight:600;">Struktur Organisasi</span>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-center pt-5">
                        <div class="circle-add" v-if="structure">
                        <v-icon
                          style="color:#339933;font-size:30px;"
                          small
                        >
                          mdi-plus-circle
                        </v-icon>
                        </div>
                        <div class="circle-close" v-else>
                        <v-icon
                          style="color:#FF4433;font-size:30px;"
                          small
                        >
                          mdi-close-circle
                        </v-icon>
                        </div>
                    </b-col>
                  </b-row>
                  
                </b-card>
              </b-col>
            </b-row>


            <b-row style="margin-top:6rem;">
              <b-col cols="6">
                <b-card class="shadow border-0 position-relative" style="padding-top:7rem;cursor:pointer;" @click="rekening = !rekening">
                  <div style="position:relative; width:100%;">
                  <img
                    src="../../../assets/image/images/request/bank-account.svg"
                    alt="abstract"
                    style="position:absolute;top:-40px;top: -160px;margin: 0 auto;left: 0;right: 0;"
                  />
                  </div>
                  <b-row>
                    <b-col class="text-center">
                      <span style="font-size:14px;font-weight:600;">Rekening</span>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-center pt-5">
                        <div class="circle-add" v-if="rekening">
                        <v-icon
                          style="color:#339933;font-size:30px;"
                          small
                        >
                          mdi-plus-circle
                        </v-icon>
                        </div>
                        <div class="circle-close" v-else>
                        <v-icon
                          style="color:#FF4433;font-size:30px;"
                          small
                        >
                          mdi-close-circle
                        </v-icon>
                        </div>
                    </b-col>
                  </b-row>
                  
                </b-card>
              </b-col>
              <b-col cols="6">
                <b-card class="shadow border-0 position-relative" style="padding-top:7rem;cursor:pointer;" @click="cabang = !cabang">
                  <img
                    src="../../../assets/image/images/request/house.svg"
                    alt="abstract"
                    style="position:absolute;top:-40px;"
                  />
                  <b-row>
                    <b-col class="text-center">
                      <span style="font-size:14px;font-weight:600;">Cabang Ormas</span>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-center pt-5">
                        <div class="circle-add" v-if="cabang">
                        <v-icon
                          style="color:#339933;font-size:30px;"
                          small
                        >
                          mdi-plus-circle
                        </v-icon>
                        </div>
                        <div class="circle-close" v-else>
                        <v-icon
                          style="color:#FF4433;font-size:30px;"
                          small
                        >
                          mdi-close-circle
                        </v-icon>
                        </div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-5">
                      <b-col class="d-flex justify-content-center pt-5 mt-5">
                        <button
                          class="mt-16 text-center button-back mr-5"
                          @click="$router.push('/permohonan')"
                        >
                          Kembali
                        </button>
                        <b-button :disabled="cabang && profile && rekening && structure " class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="$bvModal.show('formInfo')">
                          Lanjut
                        </b-button>
                      </b-col>
                    </b-row>
      </b-container>
    </b-card>
  </div>
</template>
<style>
</style>
<script>
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
export default {
  name: "Informasi",
  data() {
    return {
      activeTab: 0,
      profile: true,
      structure: true,
      rekening: true,
      cabang: true,
      listPerubahan: []
    }
  },
  mounted(){
    this.getJenisPerubahan()
  },
  methods: {
    getJenisPerubahan(){
      this.$store.dispatch('getJenisPerubahan').then((res)=>{
        this.listPerubahan = res.data
      })
    },
    pilihSemua(){
      this.profile = false
      this.structure = false
      this.rekening = false
      this.cabang = false
    },
    hapusSemua() {
      this.profile = true
      this.structure = true
      this.rekening = true
      this.cabang = true
    },
    confirmChoice(){
      let dt = {
        profile: !this.profile,
        cabang: !this.cabang,
        rekening: !this.rekening,
        structure: !this.structure,
      }
      let vv = JSON.stringify(dt)
      localStorage.setItem('form-permohonan', vv)

      let ff = []
      let sJenis = []
      if(!this.profile) { 
        ff.push('profil')
        sJenis.push(1)
      }
      if(!this.structure) { 
        ff.push('struktur')
        sJenis.push(2)
      }
      if(!this.rekening) { 
        ff.push('rekening')
        sJenis.push(3)
      }
      if(!this.cabang) { 
        ff.push('cabang')
        sJenis.push(4)
      }

      localStorage.setItem('permohonan-arr', JSON.stringify(ff))
      
      this.$store.dispatch('submitJenisPerubahan', {jenisPerubahans: sJenis}).then((res)=>{
        if(!res.data.status){
            let propertyValues = Object.values(res.data.error);
            var bb = ''
            for(var i = 0; i < propertyValues.length; i++){
              bb += ' ' + propertyValues[i] + ','
            }
            bb.slice(0, -1)
            Swal.fire({
              title: res.data.message,
              text: bb,
              icon: "warning",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok",
              cancelButtonText: false
            })
          } else {
            this.$router.push('/permohonan/form/filling?id='+ res.data.data.id)
          }
      }).catch(() =>{
          Swal.fire({
            title: 'Terjadi Kesalahan',
            text: '',
            icon: "warning",
            confirmButtonColor: "#063A69",
            confirmButtonText: "Ok",
            cancelButtonText: false
          })
        })
      
    }
  }

};
</script>

<style>

.ic-gray {
  color:gray !important;font-size:20px;
}
.ic-green {
  color:#339933 !important;font-size:20px;
}

.table.table-head-bg thead tr,
.table.table-head-bg thead th {
  background-color: #333333;
}
.table.table-head-custom thead tr,
.table.table-head-custom thead th {
  color: #ffffff !important;
  text-align: center;
}
.table.table-head-custom tbody tr,
.table.table-head-custom tbody td {
  text-align: center;
}

.circle-close {
  font-size:14px;font-weight:600;border:1px solid #FF4433;width:45px; height:45px; border-radius:50%;display:flex; justify-content:center;align-items:center; margin:0 auto;
}

.circle-add {
  font-size:14px;font-weight:600;border:1px solid #339933;width:45px; height:45px; border-radius:50%;display:flex; justify-content:center;align-items:center; margin:0 auto;
}
</style>
